import React from 'react'
import Navbar from './Navbar'

const Rationale = () => {
  
  return (
    <div id="rationale" className='rationale w-full h-screen bg-[#b02323] text-gray-300'>
    <Navbar />
    <h1>Rationale</h1>


    </div>
  )
}

export default Rationale